<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(status)="{ item }">
            <span :class="colorClass(item)">{{ item.status_name }}</span>
          </template>
          <template v-slot:cell(actions)="{ item }">
            <div class="d-flex justify-content-center">
              <router-link :to="'/booking/details/' + item.id">
                <b-button variant="link" class="text-warning px-1 py-0">
                  <font-awesome-icon icon="pencil-alt" title="Edit" />
                </b-button>
              </router-link>
            </div>
          </template>
          <template v-slot:cell(description)="{ item }">
            <div v-if="item.description.length == 0">-</div>
            <div v-else>
              <div v-for="(q, i) of item.description" :key="q.name">
                <div v-if="i < 2">
                  <div class="time-color">{{ q.name }}</div>
                  <div>{{ q.value }}</div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:cell(username)="{ item }">
            <!-- <router-link :to="`/report/customer/${item.user_guid}?tab=4`"> -->
            <div>
              {{ item.firstname_answer }} {{ item.lastname_answer }} ({{
                item.username
              }})
            </div>
            <!-- </router-link
            > -->
            <div class="time-color">
              {{ item.telephone | moment("HH:mm:ss") }}
            </div>
          </template>
          <template v-slot:cell(booking_date)="{ item }">
            <div>{{ item.booking_date | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ item.booking_date | moment("HH:mm:ss") }}
            </div>
          </template>
          <template v-slot:cell(created_time)="{ item }">
            <div>{{ item.created_time | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ item.created_time | moment("HH:mm:ss") }}
            </div>
          </template>
          <template v-slot:cell(updated_time)="{ item }">
            <div>{{ item.updated_time | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ item.updated_time | moment("HH:mm:ss") }}
            </div>
          </template>
          <template v-slot:cell(sale_branch)="{ item }">
            <div v-if="!item.sale_branch && !item.sale">-</div>
            <div>{{ item.sale_branch || "" }}</div>
            <div class="time-color">
              {{ item.sale || "" }}
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
  },
  methods: {
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    colorClass(item) {
      if (item.status == "1") return "text-warning";
      else if (item.status == "2") return "text-success";
      else if (item.status == "3") return "text-danger";
      else return "text-secondary";
    },
    chkBirthday(birthday) {
      let birthDate = birthday.search("00:00:00");
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}
</style>
